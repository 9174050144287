import 'hammerjs';
import { enableProdMode, importProvidersFrom } from '@angular/core';

// import 'tailwindcss/tailwind.css';
import * as Sentry from '@sentry/angular';
import { registerLicense } from '@syncfusion/ej2-base';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import minmax from 'dayjs/plugin/minMax';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { InputTextModule } from 'primeng/inputtext';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  MatDialogClose,
  MatDialogContent,
  MatDialogModule,
  MatDialogTitle,
} from '@angular/material/dialog';
import { OverlayModule } from '@angular/cdk/overlay';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatInputModule } from '@angular/material/input';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ReactiveFormsModule } from '@angular/forms';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { IntercomModule } from '@supy-io/ngx-intercom';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { provideAnimations } from '@angular/platform-browser/animations';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { NG_SCROLLBAR_OPTIONS, NgScrollbarModule } from 'ngx-scrollbar';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import packageInfo from '../package.json';
import { RestRequestIntercept } from './app/restApi/rest-request.intercept';
import { GeneralGuard } from './app/guards/general-guard';
import { AuthGuard } from './app/guards/auth-guard.service';
import { INTERCOM_ID } from './app/framework/constants/general.constants';
import { AppRoutingModule } from './app/app-routing.module';
import { metaReducers, reducers } from './app/store/app-state';
import { WebappModule } from './app/pages/webapp/webapp.module';
import { AppComponent } from './app/app.component';
import { REST_BASE } from './app/restApi/RestRoutes';
import { environment } from './environments/environment';

// visit: https://www.syncfusion.com/account/downloads
registerLicense(environment.syncfusion);

if (environment.production) {
  enableProdMode();
}

dayjs.extend(utc);
dayjs.extend(minmax);
dayjs.extend(isSameOrBefore);
dayjs.extend(localizedFormat);

// Sentry.init({
//   dsn: environment.sentry_dsn,
//   allowUrls: ['staging.skillhop.com', 'skillhop.com'],
//   environment: environment.sentryProduction ? 'production' : 'staging',
//   integrations: [
//     new BrowserTracing({
//       tracingOrigins: [REST_BASE],
//       routingInstrumentation: Sentry.routingInstrumentation,
//     }),
//   ],
//
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

// TODO: Change URL's to use buildevo.com
Sentry.init({
  dsn: environment.sentry_dsn,
  allowUrls: ['https://staging.skillhop.com', 'https://skillhop.com'], // Ensure URLs include protocols
  environment: environment.sentryProduction ? 'production' : 'staging',
  integrations: [Sentry.browserTracingIntegration()],
  tracePropagationTargets: [REST_BASE],
  tracesSampleRate: environment.sentryProduction ? 0.2 : 1.0, // Adjust based on environment
  // this release should be the same as the one in gitlab-ci.yml
  release: `skillhop-frontend@${packageInfo.version}`,
});

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      RouterModule,
      CommonModule,
      BrowserModule,
      AppRoutingModule,
      MatSidenavModule,
      MatButtonModule,
      MatSlideToggleModule,
      MatCheckboxModule,
      MatFormFieldModule,
      NgScrollbarModule,
      IntercomModule.forRoot({
        appId: INTERCOM_ID, // from your Intercom config
        updateOnRouterChange: true, // will automatically run `update` on router event changes. Default: `false`
      }),
      EffectsModule.forRoot([]),
      StoreModule.forRoot(reducers, {
        metaReducers,
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
        },
      }),
      environment.enableReduxDevTools
        ? StoreDevtoolsModule.instrument({
            logOnly: environment.production,
            serialize: {
              options: {
                map: true,
                set: true,
              },
            },
          })
        : [],
      ReactiveFormsModule,
      ScrollingModule,
      WebappModule,
      MatInputModule,
      ImageCropperModule,
      OverlayModule,
      MatDialogModule,
      MatTooltipModule,
      MatSelectModule,
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: environment.enableServiceWorker,
        // Register the ServiceWorker as soon as the application is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000',
      }),
      InputTextModule,
      MatDialogClose,
      MatLabel,
      MatDialogContent,
      MatDialogTitle,
    ),
    AuthGuard,
    HttpClient,
    GeneralGuard,
    { provide: HTTP_INTERCEPTORS, useClass: RestRequestIntercept, multi: true },
    {
      provide: NG_SCROLLBAR_OPTIONS,
      useValue: {
        appearance: 'standard',
      },
    },
    provideCharts(withDefaultRegisterables()),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
  .then(() => console.log(`Bootstrap success`))
  .catch((err) => console.error(err));
