import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { ROUTE_AUTH } from '../../framework/constants/route.auth.constants';
import { fadeIn } from '../../../assets/styles/animations';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { ResetPasswordComponent } from './reset/reset-password.component';
import { SvgComponent } from 'src/app/framework/svg/svg.component';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  imports: [
    CommonModule,
    MatButtonModule,
    RouterModule,
    LoginComponent,
    RegisterComponent,
    ThankYouComponent,
    ResetPasswordComponent,
    SvgComponent,
  ],
  standalone: true,
  animations: [fadeIn],
})
export class AuthComponent implements OnInit {
  isRegister = false;
  currentPage: ROUTE_AUTH;
  readonly ROUTE_AUTH = ROUTE_AUTH;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.route.url.subscribe((url) => {
      const currentPage = url?.[0]?.path;
      if (currentPage === ROUTE_AUTH.LOGIN) {
        this.currentPage = currentPage;
        this.isRegister = false;
        return;
      }
      if (currentPage === ROUTE_AUTH.RESET_PASSWORD) {
        this.currentPage = currentPage;
        this.isRegister = false;
        return;
      }
      if (currentPage === ROUTE_AUTH.REGISTER) {
        this.currentPage = currentPage;
        this.isRegister = true;
        return;
      }
      if (currentPage === ROUTE_AUTH.THANK_YOU) {
        this.currentPage = currentPage;
        this.isRegister = true;
        return;
      }

      this.router.navigate(['landing']);
    });
  }

  toggleIsRegister() {
    this.isRegister = !this.isRegister;
  }
}
