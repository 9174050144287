<div class="flex-1 w-full h-full box-border px-12 py-14 bg-grey_300 rounded-2xl max-lg:hidden">
  <div class="h-full flex flex-col justify-center items-center relative">
    <div class="absolute top-0 left-0 w-full flex justify-end">
      <img src="assets/icons/buildevo_logo_full.svg" alt="BuildEvo Logo" class="h-6" />
    </div>
    <div class="flex flex-col gap-2">
      <div class="text-3xl text-navy_700 font-semibold">Thank You For Registering!</div>
      <div class="text-lg text-grey_900">
        You will receive an email confirmation shortly, with an account activation link.
      </div>
    </div>
  </div>
</div>
