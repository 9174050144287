<section class="h-full flex flex-col justify-between items-center">
  <div class="w-full flex flex-col mt-44 box-border px-8">
    <div class="flex flex-col gap-2 w-full">
      <h1 class="my-0 text-3xl font-semibold">Password Reset</h1>
      <h2 class="my-0 text-lg text-grey_900 font-normal">Enter your email address below and we will contact you.</h2>
    </div>
    <div class="flex flex-col gap-2" (keyup.enter)="send()">
      <p class="text-color_reject">
        @if (message) {
          {{ message }}
        }
      </p>
      <div class="mt-4">
        <app-floating-input [formControl]="email" label="Email" placeholder="Email" ngDefaultControl>
        </app-floating-input>
      </div>
      <button class="be-button mt-4" [disabled]="!email.valid" (click)="send()">Send Request</button>
    </div>
  </div>
  <div class="flex justify-center items-center mt-8 gap-1">
    <p class="text-grey_900 font-normal">Or you can go back</p>
    <a routerLink="/auth/login" class="text-color_secondary font-semibold no-underline">to the login page</a>
    <p class="text-grey_900 font-normal">instead.</p>
  </div>
</section>
