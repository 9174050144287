import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FloatingInputComponent } from '../../../framework/inputs/floating-input/floating-input.component';
import { NotificationsService } from '../../../services/notifications.service';
import { CurrentUserService } from '../../../services/current-user.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  standalone: true,
  providers: [NgForm],
  imports: [
    CommonModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    FloatingInputComponent,
    RouterLink,
  ],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  email = new FormControl('', [Validators.required, Validators.email]);
  message = null;

  isDestroyed$ = new Subject();

  constructor(
    private user: CurrentUserService,
    private route: ActivatedRoute,
    private router: Router,
    private notif: NotificationsService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.pipe(takeUntil(this.isDestroyed$)).subscribe(async (params) => {
      const token = params['reset_token'];
      if (!token) {
        return;
      }
      this.notif.showLoading();
      try {
        await this.user.confirmReset(token);
        this.notif.showSuccess("We've sent you a new password!");
        this.message = null;
        await this.router.navigate(['auth', 'login']);
      } catch (err) {
        this.notif.showError(err);
        this.message = err;
      }
    });
  }

  async send() {
    if (this.email.invalid) {
      return;
    }
    this.notif.showLoading();
    try {
      await this.user.requestReset(this.email.value);
      this.notif.showSuccess('Successfully requested! Please check your email inbox.');
      this.message = null;
    } catch (err) {
      this.notif.close();
      this.message = err;
    }
  }

  ngOnDestroy() {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }
}
