<section class="h-full flex flex-col justify-between items-center">
  <div class="title">
    <div>
      <h1 class="my-0 text-3xl font-semibold">Welcome to BuildEvo</h1>
      @if (selectedPlan()) {
        <div class="mt-2 mb-0 text-grey_900 text-lg">
          Your selected plan is: <span class="font-semibold">{{ selectedPlan() | titlecase }}.</span>
        </div>
      }
      <div class="mt-2 mb-4 text-grey_900 text-lg">
        @if (isOnlyManager()) {
          You can register only as manager.
        } @else {
          Get started. Create your account now.
        }
      </div>
    </div>
    <div class="flex flex-col gap-8">
      <div class="flex gap-4">
        <a
          (click)="toggleAccountType(MAIN_USER_TYPES.MANGER)"
          class="be-link text-grey_400"
          [class.active]="accountType() === MAIN_USER_TYPES.MANGER"
        >
          Managers
        </a>
        <a
          (click)="toggleAccountType(MAIN_USER_TYPES.SERVICE_PROVIDER)"
          class="be-link text-grey_400"
          [class.active]="accountType() === MAIN_USER_TYPES.SERVICE_PROVIDER"
          [class.disabled]="isOnlyManager()"
        >
          Service Providers
        </a>
      </div>
      <div (keyup.enter)="register()">
        @if (accountType() === MAIN_USER_TYPES.MANGER) {
          <app-register-manager-form
            @fadeInCustom
            [invitationToken]="invitationToken()"
            [registrationForm]="registrationForm"
          >
          </app-register-manager-form>
        } @else if (accountType() === MAIN_USER_TYPES.SERVICE_PROVIDER) {
          <app-register-service-provider-form
            @fadeInCustom
            [registrationForm]="registrationForm"
          ></app-register-service-provider-form>
        }
      </div>
      <button
        class="be-button"
        [matTooltip]="registrationForm.invalid ? 'Please enter valid data' : ''"
        [disabled]="registrationForm.invalid || loading()"
        (click)="registrationForm.valid && register()"
      >
        Register
      </button>
    </div>
    <div class="flex justify-center items-center gap-1">
      <p class="text-grey_900 font-normal">Have an account?</p>
      <a routerLink="/auth/login" class="text-color_secondary font-semibold no-underline">Login</a>
    </div>
  </div>
</section>
