<div class="h-full w-full flex justify-center items-start">
  <div class="w-full h-full flex gap-16 box-border p-8 max-w-[2000px] overflow-y-auto">
    <div class="flex-1 box-content px-8 max-lg:px-16 max-sm:px-0 min-h-[850px] max-h-[1500px]">
      <app-login *ngIf="currentPage === ROUTE_AUTH.LOGIN"></app-login>
      <app-reset-password *ngIf="currentPage === ROUTE_AUTH.RESET_PASSWORD"></app-reset-password>
      <app-register *ngIf="currentPage === ROUTE_AUTH.REGISTER"></app-register>
      <app-thank-you *ngIf="currentPage === ROUTE_AUTH.THANK_YOU"></app-thank-you>
    </div>

    <section
      *ngIf="currentPage !== ROUTE_AUTH.THANK_YOU"
      class="flex-1 box-border px-12 py-14 bg-grey_300 rounded-2xl max-lg:hidden min-h-[850px] max-h-[1500px]"
    >
      <div class="h-full flex flex-col justify-between items-center">
        <div class="w-full flex justify-end">
          <a routerLink="/landing">
            <img src="assets/icons/buildevo_logo_full.svg" alt="BuildEvo Logo" class="h-6" />
          </a>
        </div>
        <div class="title">
          <div class="text-3xl text-navy_700 font-semibold">
            Let us help revolutionize your construction management.
          </div>
          <div class="text-lg text-grey_900">Create an account here and get started.</div>
        </div>
        <div
          class="relative bg-blue_500 text-navy_700 w-auto box-content px-12 pt-14 pb-10 bottom-0 left-0 rounded-2xl text-2xl font-semibold max-w-4xl"
        >
          <app-svg class="absolute top-5 left-5 w-10 h-10" src="assets/icons/quote.svg"></app-svg>
          With BuildEvo, we're not just offering software - we're delivering a solution born from real industry
          experience and tailored to drive your success.
        </div>
      </div>
    </section>
  </div>
</div>
